[data-theme="dark"] {
  .text-blue {
    color: #3ba7ff !important;
  }

  .text-danger {
    color: #ff7a7a !important;
  }

  .main_wrapper {
    background-color: #2c3e50 !important;
  }

  .bg-lightblue {
    background-color: #2c3e50 !important;
  }

  .bg {
    background: #435058 !important;
  }

  .bg-info {
    background: #182a46 !important;
  }

  .bg-info2 {
    background: #4f5257 !important;
  }

  .main-div {
    background: #30363b !important;
  }

  .bg-header {
    background: #2c3e50 !important;
  }

  .loggedinuser {
    color: white;
    background-color: #2c3e50 !important;
  }

  .highlight {
    color: white;
    background-color: #405264 !important;
  }

  .statement_row:hover td {
    color: white;
    background-color: #405264 !important;
  }

  table tr:nth-child(odd) {
    background-color: #171a1b !important;
    color: #d9edf7;
  }

  table tr:nth-child(even) {
    background-color: #495057 !important;
    color: #d9edf7;
  }

  .modal .modal-content {
    background-color: #000 !important;
    color: #fff !important;
  }

  input,
  select {
    background-color: #2c3e50 !important;
    color: #fff !important;
    border-radius: 3px !important;
    border: 1px solid #d8d7d7;
  }

  input:disabled,
  input:read-only,
  select:disabled {
    background-color: #1a2530 !important;
    color: #bdbdbd;
    cursor: not-allowed;
  }

  .circle {
    background: #315969 !important;
  }

  .login-theme, .form_inputs_area {
    background: #272a2c !important;
  }

  .form_content {
    background: #414141 !important;
  }
  
  .danger-row {
    background-color: rgb(120, 40, 50) !important;
    color: rgb(255, 221, 221) !important;
  }
  
  .success-row {
    background-color: rgb(50, 120, 70) !important;
    color: rgb(203, 255, 229) !important;
  }

  .warning-row {
    background-color: rgb(90, 90, 40) !important;
    color: rgb(255, 255, 204) !important;
  }
}
