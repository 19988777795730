#header {
  position: absolute;
  /* width: 180px; */
}
#header .pro-sidebar {
  height: 100vh;
}
#header .closemenu {
  color: #ffffff;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 35px;
  right: 0;
  cursor: pointer;
}
#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}
#header .pro-sidebar.collapsed {
  width: 41px;
  min-width: 41px;
}
#header .pro-sidebar-inner {
  background-color: #2c3e50;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding-top: 5px;
  text-align: center;
  color: #ecf0f1;
  font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 2px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #eeecf1;
  padding: 2px 2px;
  transition: all 0.1s ease;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: black
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: transparent;
  color: #9dcdec; /* Lighter shade of blue */
}
#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%);
}
#header .logo {
  padding: 20px;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item{
    font-size: 12px;
    font-weight: 500;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item{
    background: transparent;
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
  background-color: #34495e;
  max-height: 100vh;
  overflow-y: auto;
  padding-left: 5px;
  margin: 0px!important;
  border-radius: 4px;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item, .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 3px 10px 3px 10px;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 3px 0px 3px 1px;
  cursor: pointer;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0;
}
.hover_underline ul li.pro-menu-item:hover .pro-item-content{
  background: #d9edf7;
  padding-left: 7px;
  transition: 0.1s ease-in;
}
.hover_underline_submenu .pro-item-content{
  background: transparent !important;
  padding-left: 0px !important;
  transition: unset !important;
}
.hover_underline_submenu .pro-item-content:hover{
  background: #d9edf7 !important;
  padding-left: 7px !important;
  transition: 0.1s ease-in !important;
}
.plusiconleft{
  width: 14px;
  height: 14px;
  padding: 2px;
}
/* .hover_underline_submenu ul li.pro-menu-item:hover .pro-item-content{
  background: green;
} */
.hover_underline::before ul li.pro-menu-item:hover .pro-item-content{
  background: #d9edf7;
}
.mobile_btn{
  display: none;
}

.sidebar1 {
  height: 100%;
  overflow-y: auto;
  transition: width 0.3s ease;
}
  
.sidebar1.collapsed::-webkit-scrollbar {
  display: none;
}

/* custom dropdown menu styling */
/* .testing li{
  padding-left: 10px;
}
.testing li .left{
  background: #fff;
  width: 85%;
  float: left;
}
.testing li .left a{
  display: block;
  font-size: 11px;
  height: 22px;
  line-height: 22px;
  text-align: justify;
  padding-left: 25px;
  color: #000!important;
}
.testing li .left a:hover{
  background: #d9edf7;
  transition: 0.1s ease-in;
}
.testing li .right{
  background: yellowgreen;
  width: 15%;
  float: left;
}
.fa-angle-right{
  font-size: 12px!important;
  padding: 5px 8px;
  background-color: #d9edf7;
  position: absolute;
  color: #000;
} */

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}
@media only screen and (max-width: 800px) {
  html {
    overflow: hidden;
  }
  #header .pro-sidebar.collapsed {
    width: 0px;
    min-width: 0px;
  }
  .mobile_btn{
    display: flex;
    position: absolute; 
    z-index: 1;
    font-size:20px;
    padding: 2px; 
    border-radius:5px; 
    top: 6px;
    left: 10px;
    width:25px; 
    height:25px;
    background:white;
  }
  [data-theme="dark"] .mobile_btn{
    color: black;
  }

  #header .closemenu {
    color: #ffffff;
    position: absolute;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 22px;
    top: 35px;
    right: 0;
    cursor: pointer;
  }
}