html, body{
  /* overflow-x: hidden; */
  border: none;
  outline: none;
  overflow-x: hidden;
  overflow-y:initial;
  scroll-behavior: smooth !important;
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  font-size: 12px;
  font-family: "Roboto", Helvetica, sans-serif !important;
  font-weight: 600 !important;
}
[data-theme="dark"] {
  background-color: #282a2c !important;
  color: white;
}

.urdu {
  font-family: "Noto Nastaliq Urdu", serif;
  font-optical-sizing: auto;
  font-weight: 700 !important;
  font-style: normal;
}

@media only screen and (max-width: 520px){
  html, body{
    overflow-y: scroll !important;
  }
}
@media only screen and (max-width: 768px){
  html, body{
    overflow-y: scroll !important;
  }
}

@media print{
  html, body{
  height: unset !important;
  overflow-x: hidden;
  overflow-y:initial;
  scrollbar-width: none;
  scroll-behavior: smooth !important;
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Calibri'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
